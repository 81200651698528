<template >
	<div>
		<custom-upload @updatedValue="onInputChange" :fileParams="imageUploadParams"></custom-upload>
		<el-tabs tab-position="left" style="height: 200px;">
			<el-tab-pane v-for="(page, i) in portailNode.pages" :key="i" :label="page.pageLabel">
				<div class="editor" v-if="editor[i]">
					<menu-bar class="editor__header" :editor="editor[i]" />
					<editor-view v-model="page.text" class="editor__content" :editor="editor[i]" />
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>


</template>
<script>
import { Tabs, TabPane } from 'element-ui';
import UploadFile from './UploadFile.vue';
import { Editor } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import HardBreak from '@tiptap/extension-hard-break';

import MenuBar from './MenuBar.vue';
import EditorView from "./EditorView.vue";

export default {
	name: 'HomePageEdit',
	components: {
		'el-tabs': Tabs,
		'el-tab-pane': TabPane,
		'custom-upload': UploadFile,
		'editor-view': EditorView,
		'menu-bar': MenuBar,
	},
	props: {
		portailNode: {
			type: Object,
		},
	},
	data() {
		return {
			saveTimeOut: undefined,
			imageUploadParams: {
				fileType: "image",
				fileNodeValue: this.portailNode.imagePages,
				fileBaseUrl: `${this.$DOCUMENTROOT_URL}/commun/img/accueil`,
				siteID: 'deleteID',
				currentNode: this.portailNode,
				deletePreviousSiteFile: "imagePagesPortail"
			},
			editor: [
				new Editor({
					editorProps: {
						handlePaste: (editor, e, slice) => {
							this.editor.commands.setContent(e.clipboardData.getData('text/plain'));
							return true;
						},
					},
					enablePasteRules: false,
					enableInputRules: false,
					extensions: [
						StarterKit.configure({
							hardBreak: false
						}),
						TextAlign.configure({
							types: ['heading', 'paragraph'],
						}),
						HardBreak.extend({
							addKeyboardShortcuts() {
								return {
									Enter: () => this.editor.commands.setHardBreak()
								}
							}
						})
					],
					onUpdate: () => {
						// HTML
						this.$nextTick(() => {
							this.onInputChange();
						});
					},
				}),
				new Editor({
					editorProps: {
						handlePaste: (editor, e, slice) => {
							this.editor.commands.setContent(e.clipboardData.getData('text/plain'));
							return true;
						},
					},
					enablePasteRules: false,
					enableInputRules: false,
					extensions: [
						StarterKit.configure({
							hardBreak: false
						}),
						TextAlign.configure({
							types: ['heading', 'paragraph'],
						}),
						HardBreak.extend({
							addKeyboardShortcuts() {
								return {
									Enter: () => this.editor.commands.setHardBreak()
								}
							}
						})
					],
					onUpdate: () => {
						// HTML
						this.$nextTick(() => {
							this.onInputChange();
						});
					},
				}),
				new Editor({
					editorProps: {
						handlePaste: (editor, e, slice) => {
							this.editor.commands.setContent(e.clipboardData.getData('text/plain'));
							return true;
						},
					},
					enablePasteRules: false,
					enableInputRules: false,
					extensions: [
						StarterKit.configure({
							hardBreak: false
						}),
						TextAlign.configure({
							types: ['heading', 'paragraph'],
						}),
						HardBreak.extend({
							addKeyboardShortcuts() {
								return {
									Enter: () => this.editor.commands.setHardBreak()
								}
							}
						})
					],
					onUpdate: () => {
						// HTML
						this.$nextTick(() => {
							this.onInputChange();
						});
					},
				}),
				new Editor({
					editorProps: {
						handlePaste: (editor, e, slice) => {
							this.editor.commands.setContent(e.clipboardData.getData('text/plain'));
							return true;
						},
					},
					enablePasteRules: false,
					enableInputRules: false,
					extensions: [
						StarterKit.configure({
							hardBreak: false
						}),
						TextAlign.configure({
							types: ['heading', 'paragraph'],
						}),
						HardBreak.extend({
							addKeyboardShortcuts() {
								return {
									Enter: () => this.editor.commands.setHardBreak()
								}
							}
						})
					],
					onUpdate: () => {
						// HTML
						this.$nextTick(() => {
							this.onInputChange();
						});
					},
				})
			],
		};
	},
	methods: {
		onInputChange() {
			// Debouncing input
			clearTimeout(this.saveTimeOut);
			this.saveTimeOut = setTimeout(() => {
            	this.$emit('updatedData');
			}, 500);
		}
	},
	// created() {
	// 	console.log('this.portailNode :>> ', this.portailNode);
	// }
}
</script>

<style lang="scss">
.is-left {
	flex-direction: column;
}
</style>

<style lang="scss" scoped>
.collapse {
	background-color: #fff;
	height: 100%;

	&__item {
		max-width: 820px;
		margin: 0 auto;
	}
}


.editor {
	display: flex;
	flex-direction: column;
	max-height: 26rem;
	color: #0d0d0d;
	background-color: #fff;
	border: 1px solid #eaeaea;
	border-radius: 0.75rem;

	&__header {
		display: flex;
		align-items: center;
		flex: 0 0 auto;
		flex-wrap: wrap;
		padding: 0.25rem;
		border-bottom: 1px solid #eaeaea;
	}

	&__content {
		padding: 1.25rem 1rem;
		flex: 1 1 auto;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}
}
</style>