<template>
    <div>
        <div class="section ">
            <h2>Carousel</h2>
            <div class="image-field">
                <Upload :accept="'image/jpeg, image/jpg, image/webp, image/png'" class="upload-demo" :http-request="onFileImgHomeChange" action :on-preview="handlePreview" :on-remove="handleRemove" :file-list="landingPageImageUrl" list-type="picture">
                    <el-button size="small" type="primary">Cliquer pour ajouter</el-button>
                    <div slot="tip" class="el-upload__tip">jpg/png avec une taille inférieur à 500kb</div>
                </Upload>
            </div>
        </div>
        <div class="section homepage__siteVedette">
            <h2>Site vedette</h2>
            <div>
                <label class="select-label" for="site-select">Choisissez un site:</label>
                <Select empty="vide." no-data-text="vide" @change="saveAccueil()" v-model="vedetteSite" clearable placeholder="Select">
                    <Option v-for="item in listeSites" :key="item.value" :label="item.label" :value="item.value"></Option>
                </Select>
            </div>
        </div>
        <div class="section homepage__sitesMisEnAvant">
            <h3>Sites mis en avant</h3>
            <div>
                <label class="select-label" for="site-select">Choisir 3 sites différents:</label>
                <Select class="select" empty="vide." no-data-text="vide" @change="saveAccueil($event)" v-model="aLaUneSites[0]" clearable placeholder="Select">
                    <Option v-for="item in listeSites" :key="item.value" :label="item.label" :value="item.value"></Option>
                </Select>
                <Select class="select" empty="vide." no-data-text="vide" @change="saveAccueil($event)" v-model="aLaUneSites[1]" clearable placeholder="Select">
                    <Option v-for="item in listeSites" :key="item.value" :label="item.label" :value="item.value"></Option>
                </Select>
                <Select class="select" empty="vide." no-data-text="vide" @change="saveAccueil($event)" v-model="aLaUneSites[2]" clearable placeholder="Select">
                    <Option v-for="item in listeSites" :key="item.value" :label="item.label" :value="item.value"></Option>
                </Select>
            </div>
        </div>
    </div>
</template>
<script>
import { Button, Upload, Input, Dialog, Select, Option, Tabs, TabPane } from 'element-ui';
const axios = require('axios');
import 'element-ui/lib/theme-chalk/index.css';

export default {
    name: 'HomePageEdit',
    components: {
        'el-button': Button,
        'el-tabs': Tabs,
        'el-tab-pane': TabPane,
        Upload,
        Input,
        Select,
        Dialog,
        Option,
    },
    props: {
        portailNode: {
            type: Object,
        },
    },
    data() {
        return {
            landingPageImageUrl: [],
            dialogVisible: false,
            disabled: false,
            listeSites: [],
            aLaUneSites: [],
            vedetteSite: [],
            vedetteImg: '',
        };
    },
    mounted() {


        this.aLaUneSites = this.portailNode.listSites;
        if (this.portailNode.siteVedette) this.vedetteSite = this.portailNode.siteVedette;

        this.landingPageImageUrl = this.portailNode.imagesLandingpage;
        this.vedetteImg = this.portailNode.siteVedetteImg;

        axios
            .get(`${this.$API_URL}/sites/`)
            .then((response) => {
                // handle success
                response.data.forEach((site) => {
                    this.listeSites.push({ value: site.data.sortedName, label: site.title });
                });
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    },
    methods: {

        handleRemove(file) {
            let imageName = file.name.split('.')[0];
            let imageFormat = file.name.split('.')[1];

            let indexToDelete = this.landingPageImageUrl.findIndex((image) => image.name == file.name);
            this.landingPageImageUrl.splice(indexToDelete, 1);

            axios
                .get(`${this.$API_URL}/homepage/delete/${imageName}/${imageFormat}`)
                // .then(function (response) {
                //     console.log('response :>> ', response);
                // })
                // .catch(function (error) {
                //     console.log(error);
                // });
        },
        handlePictureCardPreview(file) {
            // this.landingPageImageUrl = file.url;
            this.dialogVisible = true;
        },
        handlePreview(file) {
            console.log(file);
        },
        saveAccueil(event) {
            this.portailNode.listSites = this.aLaUneSites;
            this.portailNode.imagesLandingpage = this.landingPageImageUrl;
            this.portailNode.siteVedette = this.vedetteSite;
            this.portailNode.siteVedetteImg = this.vedetteImg;

            this.$emit('updatedData');
        },

        onFileImgHomeChange: function (e) {
            console.log('test onFileImgHomeChange');
            this.uploadFile('commun', e.file, 1920, 1080, 'home');
        },
        uploadFile: function (pSiteID, pFile, pWidth, pHeight, pModule) {
            let self = this;
            console.log('uploadFile');

            let fd = new FormData();
            fd.append('file', pFile);
            fd.append('imageWidth', pWidth);
            fd.append('imageHeight', pHeight);
            fd.append('siteID', pSiteID);
            fd.append('folder', pModule);

            axios({
                method: 'post',
                url: `${this.$API_URL}/upload/commun`,
                data: fd,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
                .then(function (response) {
                    //handle success
                    console.log('response :>> ', response);
                    console.log('self.landingPageImageUrl :>> ', self.landingPageImageUrl);
                    if (pModule === 'home') {
                        self.landingPageImageUrl.push({ name: response.data.fileName, url: `${self.$DOCUMENTROOT_URL}/commun/img/accueil/home/${response.data.fileName}` });
                    } else {
                        console.log('pFile :>> ', pFile);
                        self.vedetteImg = response.data.fileName;
                    }
                    self.saveAccueil();
                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                });
        },
    },
}
</script>
<style lang="scss" scoped>
.section {
    background-color: #fff;
    padding: 20px;
}

.image-field {
    display: flex;
    gap: 10px;
}

img {
    max-width: 100%;
}

.select-label {
    display: block;
}

.el-upload__text {
    position: relative;
}

.el-icon-upload {
    position: relative;
}

.uploaded-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: contain;
}
</style>