<template>
	<div class="homepage">
		<h1>Édition du portail des expositions</h1>
		<main>
			<el-tabs class="tabs" type="card">
				<el-tab-pane label="Accueil">
					<Accueil v-if="nodePortail" @updatedData="savePortail" :portailNode="nodePortail" />
				</el-tab-pane>
				<el-tab-pane label="Autres pages">
					<AutresPages v-if="nodePortail" @updatedData="savePortail" :portailNode="nodePortail" />
				</el-tab-pane>
				<el-tab-pane :disabled="true" class="buttons-pane" label="External link" name="third">
					<template #label>
						<a :href="`${$DOCUMENTROOT_URL}/`" target="_blank" class="btn blue" type="button">Voir</a>
						<button @click="publishAccueil()" class="btn blue" type="button">Publier</button>
					</template>
				</el-tab-pane>
			</el-tabs>
		</main>
	</div>
</template>

<script>
import { Button, Upload, Input, Dialog, Select, Option, Tabs, TabPane } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
const axios = require('axios');
import Accueil from '../components/PortailAccueilTab.vue';
import AutresPages from '../components/PortailAutresPages.vue';


export default {
	name: 'HomePageEdit',
	components: {
		'el-button': Button,
		'el-tabs': Tabs,
		'el-tab-pane': TabPane,
		Upload,
		Input,
		Select,
		Dialog,
		Option,
		Accueil,
		AutresPages
	},
	data() {
		return {
			nodePortail: undefined,
		};
	},
	methods: {
		publishAccueil() {
			axios
				.post(`${this.$API_URL}/portail`)
				.then( (response) => {
					this.$notify({
						title: 'Succès',
						message: 'Le portail a bien été publié',
						type: 'success'
					});
				})
				.catch(function (error) {
					console.error(error);
				});
		},
		savePortail() {

			console.log('nodePortail :>> ', this.nodePortail);
			axios
				.put(`${this.$API_URL}/portail`, { jsonFile: this.nodePortail }, { headers: { 'content-type': 'application/json' } })
				// .then((response) => {
				// 	console.log('response :>> ', response);
				// })
				// .catch(function (error) {
				// 	console.log(error);
				// });
		}
	},
	created() {
		axios
			.get(`${this.$API_URL}/portail/`)
			.then((response) => {
				// handle success
				this.nodePortail = response.data;
				// console.log('this.nodePortail :>> ', this.nodePortail);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			});
	}
};
</script>

<style lang="scss">
h2 {
	font-size: 2rem;
}

.homepage {
	width: 100%;
	height: 100%;
	max-width: 1000px;
	margin: 0 auto;
	padding: 2rem 4rem;

	&__sitesMisEnAvant .select {
		margin: .5rem;
	}

	.tabs {
		height: 100%;
	}
}

.homepage main {
	width: 100%;
	height: 100%;

}

.el-tabs__nav {
	display: flex;
	float: unset;
}

#tab-third {
	margin-left: auto;
}
</style>
